import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {  
    Route,
    Routes,
    useNavigate     
} from 'react-router-dom';
import DashboardEndo from './CentralReader/Dashboard-Endo';
import DashboardHisto from './CentralReader/Dashboard-Histo';
import ReaderEndo from './CentralReader/Reader-Endo';
import ReaderHisto from './CentralReader/Reader-Histo';
import ReaderEndoeCRF from './CentralReader/Reader-Endo-eCRF';

import MultilevelMenu from './MultilevelMenu';
import { menuData_CentralReader_Endo } from './MenuFiles/MenuData_CentralReader_Endo';
import { menuData_CentralReader_Histo } from './MenuFiles/MenuData_CentralReader_Histo';
import { menuData_ImgSrvTeam } from './MenuFiles/MenuData_ImgSrvTeam';
import { menuData_NotoAdmin } from './MenuFiles/MenuData_NotoAdmin';
import { menuData_ReadOnly } from './MenuFiles/MenuData_ReadOnly';
import { menuData_DBDesigner } from './MenuFiles/MenuData_DatabaseDesigner';
import { menuData_ClinicalDM } from './MenuFiles/MenuData_ClinicalDataManager';
import { menuData_ImgServCoordinator } from './MenuFiles/MenuData_ImgSrvCoordinator';
import { menuData_Auditor } from './MenuFiles/MenuData_Auditor';
import { menuData_Tester } from './MenuFiles/MenuData_Tester';
import { menuData_ClinicalPM } from './MenuFiles/MenuData_ClinicalPM';
import { menuData_SuperUser } from './MenuFiles/MenuData_SuperUser';
import { MenuData_SiteUser } from './MenuFiles/MenuData_SiteUser';
import { MenuData_Sponsor } from './MenuFiles/MenuData_Sponsor';

import UserDetails from './UserDetails/UserDetails';
import AboutUs from './AboutUs';
import Designer from './Designer/Designer';
import NotoLogoTagLine from './NotoLogoTagLine';
import Studies from "./Designer/Studies";
import ImageDashboard from './ImageServices/Dashboard';
import AdvanceSearch from './ImageServices/AdvanceSearch';
import TaskDetails from './ImageServices/TaskDetails';
import GlobalSettings from './Admin/GlobalSettings';
import Notifications from './Admin/Notifications';
import Paradigms from '../components/Designer/Paradigms';
import ParadigmDesigner from '../components/Designer/ParadigmDesigner';
import DistributionList from './Admin/DistributionList';
import Distribution from './Admin/Distribution';
import NotificationDetails from './Admin/NotificationDetails';
import NotificationRuleList from './Admin/NotificationRuleList';
import NotificationRuleDetails from './Admin/NotificationRuleDetails';
import ManageSites from './Designer/ManageSites';
import AssignUserRoleList from './Admin/AssignUserRoleList';
import AssignUserByProject from './Admin/AssignUserByProject';
import ChangePassword from './UserDetails/ChangePassword';
import ManageUserList from './Admin/ManageUserList';
import IdleTimer from "../IdleTimer";
import { authAction } from '../app/auth';
import EmailContentDesigner from './Admin/EmailContentDesigner';
import ClosedTaskDashboard from './ImageServices/ClosedTaskDashboard';
import DMGlobalDashboard from './DMDashboard/DMGlobalDashboard';
import DMStudyDashboard from './DMDashboard/DMStudyDashboard';
import DMDashboardViewer from './DMDashboard/DMDashboardViewer';
import CRCurrentTask from './ImageServices/CRCurrentTask';
import SponsorsList from './Admin/SponsorsList';
import DashboardSite from './Site/Dashboard';
import CustomViewers from './Designer/CustomViewers';
import { Modal, Button } from 'react-bootstrap';
import classes from './TrialstatNoto.module.css';
import SiteDashboard from './Site/SiteDashboard';
import StudyDashboard from './Site/StudyDashboard';
import SiteDashboardViewer from './Site/SiteDashboardViewer';
import SubjectStatusReport from './Site/SubjectStatusReport';
import SiteQueryDashboard from './Site/QueryDashboard.js';
import ISCQueryDashboard from './ImageServices/ISCQueryDashboard.js';
import SponsorDashboard from './Sponsor/Dashboard';
import SponsorStudyList from './Sponsor/SponsorStudyList';
import CustomViewerList from './Sponsor/CustomViewerList';
import OutcomeEmailDesigner from './Designer/OutcomeEmailDesigner';
import OutcomeMappingDesigner from './Designer/OutcomeMappingDesigner';
import OutcomeMappingList from './Designer/OutcomeMappingList';
import DocumentMgmtList from './DoumentMgmt/DocumentMgmtList';
import DashboardViewer from './Designer/DashboardViewer';
import StudyDesignerReport from './Designer/StudyDesignerReport';
import Login from './Login';
import AddUser from './Admin/AddUser';
import OutcomeGlobalEmailList from './Designer/OutcomeGlobalEmailList';
import CRSiteAffiliation from './ImageServices/CRSiteAffiliation';
import CRUserList from './ImageServices/CRUserList';
import CRSiteAffiliationRpt from './ImageServices/CRSiteAffiliationRpt';
import GlobalReportViewer from './Designer/GlobalReportViewer';

let placeHolderWindow;
const Home = (props) => {  
    const userID = useSelector(state => state.auth.gValue01);
    const navigate = useNavigate();
    const [usrRole, setUsrRole] = useState('')     
    const [usrCRType, setUsrCRType] = useState('')
    const [show, setShow] = useState(false);
    const [idleTime, setIdleTime] = useState(null);
    const handleClose = () => {
        setShow(false);
        idleTime.Logout()
    }
    const dispatch = useDispatch();

    useEffect(() => {
        getUserRole();
        canShowDashboard();
        getIdleTimeOut();
    }, [])   
   
    const getUserRole = () => {
        fetch('api/Users/GetRoleCRTypeByID?userID=' + userID)
            .then(response => response.text())
            .then(data => {
                var RoleCRType = data.split('$')
                var role = RoleCRType[0]
                var crType = RoleCRType[1]
                setUsrRole(role);
                setUsrCRType(crType);
                { role === 'Endoscopy Central Reader' && navigate("/DashboardEndo") }
                { role === 'Histopathology Central Reader' && navigate("/DashboardHisto") }
                { crType === 'CR-Agnostic' && navigate("/DashboardHisto") }
                { role === 'Management, Image Services' && navigate("/Dashboard") }
                { role === 'Image Services Coordinator' && navigate("/Dashboard") }
                { role === 'Database Designer' && navigate("/Designer") }
                { role === 'Read-Only' && navigate("/Designer") }
                { role === 'Database Administrator' && navigate("/GlobalSettings") }
                { role === 'Clinical Data Manager' && navigate("/Designer") }
                { role === 'Manager, Clinical Data Management' && navigate("/Designer") }
                { role === 'Auditor' && navigate("/Dashboard") }
                { role === 'Tester' && navigate("/Designer") }
                { role === 'Clinical Project Manager' && navigate("/Dashboard") }
                { role === 'Super User' && navigate("/Designer") }
                { role === 'Site User' && navigate("/SiteDashboard") }
                { role === 'Sponsor' && navigate("/SponsorDashboard") }
            });
    };

    const canShowDashboard = () => {
        fetch('api/Users/canShowDashboardViewer')
            .then(response => response.text())
            .then(data => {
                var res = data;
                ShowHideMenuItem(res, "mnuDashboardViewer");
                ShowHideMenuItem(res, "mnuCustomReport");
            });
    };

    const ShowHideMenuItem = (mnuDisplay, mnuName) => {
        var mnuElement = document.getElementById(mnuName);
        if (mnuElement != null && mnuElement != undefined) {
            if (mnuDisplay == "Y")
                mnuElement.style.display = "";
            else
                mnuElement.style.display = "none";
        }
    };
    
    let timer;
    const getIdleTimeOut = () => {
        fetch('api/AppSettings/IdleTimeOut')
            .then(res => res.text())
            .then(duration => {
                timer = new IdleTimer({
                    timeout: duration * 60, //in seconds
                    onTimeout: () => {
                       dispatch(authAction.logout());
                    },
                    onExpired: () => { },
                    setShow: setShow
                });
                setIdleTime(timer);
                timer.StartThisSessionTimer();

                if (window.addEventListener) {
                    window.addEventListener("message", function (event) {
                        timer.updateExpiredTime();
                    });
                }
            });
        return () => {
            if (timer != null)
                timer.cleanUp();
        };
    };

    const dualScreenOpener_Endo = (TaskID, StudyParadigmCRFID, ReadAssignmentID, actionType, ReadStage, ProcedureID) => {
        if (localStorage.getItem('ReadOpened')) {
            alert('One read is already open!')
        }
        else {
            localStorage.setItem('ReadOpened', true)
            placeHolderWindow = window.open('about:blank', 'refImgWindow', 'width=1000,height=700,resizable=1,top=200,left=450')
            var timer = setInterval(function () {
                if (placeHolderWindow.closed) {
                    clearInterval(timer);
                }
            }, 500);
            navigate("/Reader-Endo-eCRF", {
                state: {
                    TaskID: TaskID,
                    StudyParadigmCRFID: StudyParadigmCRFID,
                    ReadAssignmentID: ReadAssignmentID,
                    actionType: actionType,
                    readStage: ReadStage,
                    procedureID: ProcedureID
                }
            });

            fetch('/api/AppSettings/Img_APIUrl_Endo?TaskID=' + TaskID)
                .then(response => response.text())
                .then(imgAPIurl => {
                    if (imgAPIurl == '')
                        imgAPIurl = 'about:blank'
                    placeHolderWindow.location.href = imgAPIurl;
                    placeHolderWindow.focus();
                });
        }
    }
    const dualScreenOpener_Histo_IFram_NotInUse = (TaskID, StudyParadigmCRFID, ReadAssignmentID, actionType, ReadStage, ProcedureID) => {
        if (localStorage.getItem('ReadOpened')) {
            alert('One read is already open!')
        }
        else {
            localStorage.setItem('ReadOpened',true)
            navigate("/Reader-Endo-eCRF", {
                state: {
                    TaskID: TaskID,
                    StudyParadigmCRFID: StudyParadigmCRFID,
                    ReadAssignmentID: ReadAssignmentID,
                    actionType: actionType,
                    readStage: ReadStage,
                    procedureID: ProcedureID
                }
            });
            fetch('/api/AppSettings/Img_APIUrl_Histo?TaskID=' + TaskID)
                .then(response => response.text())
                .then(imgAPIurl => {
                    if (imgAPIurl != '') {                       
                        placeHolderWindow = window.open('about:blank', 'refImgWindow', 'width=1000,height=700,resizable=1,top=200,left=450')
                        // Check if the window was successfully created
                        if (placeHolderWindow) {
                            // Write HTML to the new window
                            placeHolderWindow.document.write(`<html>
                                  <head>
                                    <title>Trialstat</title>
                                  </head>
                                  <body>
                                    <iframe
                                      src= ${imgAPIurl}
                                      style="width: 100%; height: 100%; border: none;" 
                                      allowfullscreen></iframe>
                                  </body>
                                </html>`);
                            placeHolderWindow.document.close(); // Close the document to render it
                        } else {
                            alert('Please allow popups for this website');
                        }
                        var timer = setInterval(function () {
                            if (placeHolderWindow.closed) {
                                clearInterval(timer);
                            }
                        }, 500);
                        //placeHolderWindow.location.href = imgAPIurl;
                        placeHolderWindow.focus();
                    }
                });
        }
    }
    const dualScreenOpener_Histo = (TaskID, StudyParadigmCRFID, ReadAssignmentID, actionType, ReadStage, ProcedureID) => {
        if (localStorage.getItem('ReadOpened')) {
            alert('One read is already open!')
        }
        else {
            localStorage.setItem('ReadOpened', true)
            navigate("/Reader-Endo-eCRF", {
                state: {
                    TaskID: TaskID,
                    StudyParadigmCRFID: StudyParadigmCRFID,
                    ReadAssignmentID: ReadAssignmentID,
                    actionType: actionType,
                    readStage: ReadStage,
                    procedureID: ProcedureID
                }
            });
            fetch('/api/AppSettings/Img_APIUrl_Histo?TaskID=' + TaskID)
                .then(response => response.text())
                .then(imgAPIurl => {
                    if (imgAPIurl != '') {
                        placeHolderWindow = window.open('about:blank', 'refImgWindow', 'width=1000,height=700,resizable=1,top=200,left=450')
                        // Check if the window was successfully created

                        var timer = setInterval(function () {
                            if (placeHolderWindow.closed) {
                                clearInterval(timer);
                            }
                        }, 500);
                        placeHolderWindow.location.href = imgAPIurl;
                        placeHolderWindow.focus();
                    }
                });
        }        
    }
    const ScreenOpener_SiteUSer = (TaskID, ActType) => {
        if (localStorage.getItem('ReadOpened')) {
            alert('One read is already open!')
        }
        else {
            localStorage.setItem('ReadOpened', true)
            navigate("/Reader-Endo-eCRF", {
                state: {
                    TaskID: TaskID,
                    UserType: 'SiteUser',
                    actionType: ActType
                }
            });
        }
    }

    function Time12To24Hour(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours) + 12;
        }
        return hours + ':' + minutes;
    }

    function ConvertSqlToJsDate(date) {
        var sqlDate = date.replace(/  /g, ' ');
        var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        var sYear = '';
        var sMonth = '';
        var sDay = '';
        var time = '';
        var arrDate = sqlDate.split(' ');

        sYear = arrDate[2];
        sMonth = months.indexOf(arrDate[0].toLowerCase());
        sDay = arrDate[1];
        time = arrDate[3].replace("AM", " AM").replace("PM", " PM");

        var time24h = Time12To24Hour(time);
        var sHour = time24h.split(':')[0];
        var sMinute = time24h.split(':')[1];
        return new Date(sYear, (sMonth+1), sDay, sHour, sMinute, 0, 0);
    }

    const sortByDateTime = (firstValue, secondValue) => {
        return ConvertSqlToJsDate(firstValue) - ConvertSqlToJsDate(secondValue);
    };

    return (
        <div className="container-fluid" style={{ marginTop: "120px", width: "98%" }}>           
            <Routes>
                <Route exact path="/" />
                <Route path="/DashboardEndo" element={<DashboardEndo onClickSrcOpener={dualScreenOpener_Endo} sortByDateTime={sortByDateTime}/> } />   
                <Route path="/DashboardHisto" element={<DashboardHisto onClickSrcOpener={dualScreenOpener_Histo} sortByDateTime={sortByDateTime}/>} /> 
                <Route path="/AboutUs" element={<AboutUs/>} />
                <Route path="/Designer" element={<Studies/>} />
                <Route path="/Reader-Histo" element={<ReaderHisto/>} />
                <Route path="/Reader-Endo" element={<ReaderEndo />} />
                <Route path="/Reader-Endo-eCRF" element={<ReaderEndoeCRF />} />
                <Route path="/NewStudy" element={<Designer Action="Add" studyId="" userRole="" />} />
                <Route path="/Dashboard" element={<ImageDashboard sortByDateTime={sortByDateTime} />} /> 
                <Route path="/AdvanceSearch" element={<AdvanceSearch />} />
                <Route path="ImgTaskDetails" element={<TaskDetails />} />
                <Route path="/GlobalSettings" element={<GlobalSettings />} />       
                <Route path="/NotificationTemp" element={<Notifications />} />
                <Route path="/NotificationDetails" element={<NotificationDetails />} />
                <Route path="/Paradigms" element={<Paradigms sortByDateTime={sortByDateTime} />} />
                <Route path="/ParadigmDesigner" element={<ParadigmDesigner paradigmID="0" />} />
                <Route path="/DistributionList" element={<DistributionList sortByDateTime={sortByDateTime}/>} />
                <Route path="/Distribution" element={<Distribution />} />
                <Route path="/NotificationRuleList" element={<NotificationRuleList />} />
                <Route path="/NotificationRuleDetails" element={<NotificationRuleDetails />} />
                <Route path="/ManageSites" element={<ManageSites />} />
                <Route path="/AssignUserRoleList" element={<AssignUserRoleList />} />
                <Route path="/AssignUserByProject" element={<AssignUserByProject />} />
                <Route path="/ChangePassword" element={<ChangePassword />} />
                <Route path="/ManageUserList" element={<ManageUserList />} />
                <Route path="/EmailContentDesigner" element={<EmailContentDesigner />} />
                <Route path="/ClosedTaskDashboard" element={<ClosedTaskDashboard sortByDateTime={sortByDateTime} />} />
                <Route path="/DMGlobalDashboard" element={<DMGlobalDashboard />} />
                <Route path="/DMStudyDashboard" element={<DMStudyDashboard />} />
                <Route path="/DMDashboardViewer" element={<DMDashboardViewer />} />
                <Route path="/CRCurrentTask" element={<CRCurrentTask sortByDateTime={sortByDateTime} />} />
                <Route path="/SponsorsList" element={<SponsorsList sortByDateTime={sortByDateTime}/>} />
                <Route path="/DashboardSite" element={<DashboardSite />} /> 
                <Route path="/CustomViewers" element={<CustomViewers />} />
                <Route path="/SiteDashboard" element={<SiteDashboard onClickSrcOpener={ScreenOpener_SiteUSer}/>} />
                <Route path="/StudyDashboard" element={<StudyDashboard />} />
                <Route path="/SiteDashboardViewer" element={<SiteDashboardViewer />} />
                <Route path="/SubjectStatusReport" element={<SubjectStatusReport />} />
                <Route path="/SiteQueryDashboard" element={<SiteQueryDashboard />} />
                <Route path="/ISCQueryDashboard" element={<ISCQueryDashboard sortByDateTime={sortByDateTime} />} />
                <Route path="/SponsorDashboard" element={<SponsorDashboard />} />
                <Route path="/SponsorStudyList" element={<SponsorStudyList />} />
                <Route path="/CustomViewerList" element={<CustomViewerList />} /> 
                <Route path="/OutcomeEmailDesigner" element={<OutcomeEmailDesigner />} />
                <Route path="/OutcomeMappingDesigner" element={<OutcomeMappingDesigner />} />
                <Route path="/OutcomeMappingList" element={<OutcomeMappingList />} />
                <Route path="/DocumentMgmtList" element={<DocumentMgmtList />} />
                <Route path="/DashboardViewer" element={<DashboardViewer />} />
                <Route path="/StudyDesignerReport" element={<StudyDesignerReport />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/AddUser" element={<AddUser />} />
                <Route path="/OutcomeGlobalEmailList" element={<OutcomeGlobalEmailList />} />
                <Route path="CRSiteAffiliation" element={<CRSiteAffiliation />} />
                <Route path="CRSiteAffiliationRpt" element={<CRSiteAffiliationRpt />} />
                <Route path="CRUserList" element={<CRUserList />} />
                <Route path="GlobalReportViewer" element={<GlobalReportViewer />} />
            </Routes>
            <div id="navcontainer" className="fixedNav">
                <div id="outermostDiv" style={{ position: "static" }}>
                    <div id="logo" style={{height: "89%"}}>
                        <title>Logo</title>                       
                       <NotoLogoTagLine></NotoLogoTagLine>                                  
                    </div>
                    <div id="mainWrapper">
                        <div id="menu" className="menuItemText">
                            <div id="logosec">
                                {usrRole === 'Database Designer' && <MultilevelMenu data={menuData_DBDesigner} />}
                                {usrRole === 'Endoscopy Central Reader' && <MultilevelMenu data={menuData_CentralReader_Endo} />}
                                {usrRole === 'Histopathology Central Reader' && <MultilevelMenu data={menuData_CentralReader_Histo} />}
                                {usrCRType  === 'CR-Agnostic' && <MultilevelMenu data={menuData_CentralReader_Histo} />}
                                {usrRole === 'Management, Image Services' && <MultilevelMenu data={menuData_ImgSrvTeam} />}
                                {usrRole === 'Database Administrator' && <MultilevelMenu data={menuData_NotoAdmin} />}
                                {usrRole === 'Read-Only' && <MultilevelMenu data={menuData_ReadOnly} />} 
                                {usrRole === 'Clinical Data Manager' && <MultilevelMenu data={menuData_ClinicalDM} />} 
                                {usrRole === 'Manager, Clinical Data Management' && <MultilevelMenu data={menuData_ClinicalDM} />} 
                                {usrRole === 'Image Services Coordinator' && <MultilevelMenu data={menuData_ImgServCoordinator} />}
                                {usrRole === 'Auditor' && <MultilevelMenu data={menuData_Auditor} />}
                                {usrRole === 'Tester' && <MultilevelMenu data={menuData_Tester} />}
                                {usrRole === 'Clinical Project Manager' && <MultilevelMenu data={menuData_ClinicalPM} />}
                                {usrRole === 'Super User' && <MultilevelMenu data={menuData_SuperUser} />}
                                {usrRole === 'Site User' && <MultilevelMenu data={MenuData_SiteUser} />}
                                {usrRole === 'Sponsor' && <MultilevelMenu data={MenuData_Sponsor} />}
                                <div className="loggedInUserName" style={{ fontSize: "8px" }}>
                                    <UserDetails></UserDetails>
                                </div>
                            </div>                           
                        </div>
                    </div>
                    {
                        <div id="secondLineDiv">
                            <div style={{ marginBottom: "10px", "float": "right", marginRight: "150px" }}>
                        </div>
                    </div>
                    }
                    <div id="thirdLineDiv" style={{ height: "25px" }}>
                        <div id="locationDiv" style={{ height: "25px" }}>
                            <p id="locationParag"></p>
                        </div>
                        
                    </div>                    
                </div>
            </div>    

            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Eleven} animation={false} backdrop="static" style={{ maxWidth : "100%", width: "100%" }}>
                <Modal.Body style={{ padding: "0px"}}>
                    <div id="divTimeLeft" className="alert alert-warning" style={{ width: "100%", margin: "0px", border: "0px" }}>
                        <div className="row">
                            <div className="col-md-9">
                                <strong>Warning!</strong> The session is going to expire in <span id="spanTimeLeft"></span> seconds. 
                                Do you want to extend it?
                            </div>
                                <div className="col-md-3">
                                <Button className="btn btn-success" onClick={() => idleTime.ExtendSessionTimeout()} style={{ marginRight: "5px" }}> Yes </Button>
                                <Button variant="primary" className="btn btn-danger" onClick={handleClose}>   No </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
    
};

export default Home;
