import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import InputString from '../UI/Control/InputString';
import Card from '../UI/Card/Card';
import classes from '../TrialstatNoto.module.css'
import customStyles from '../customStyles';
import NoData from '../NoData';

const DashboardHisto = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [searchColumn, setSearchColumn] = useState(["ProtocolStudy", "ReadType", "ProcedureID"]);
    const [readsAssigned, setReadsAssigned] = useState([]);
    const [arrDisease, setArrDisease] = useState([]);
    const [arrReadType, setArrReadType] = useState([]);

    const columns = [
        {
            name: "ReadAssignmentID",
            selector: row => row.ReadAssignmentID,
            cellExport: row => row.ReadAssignmentID,
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Action",
            button: true,
            center: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'width': '7%'
            },
            cell: (row) => (
                <div>
                    {row.Action == 'review' ? <button onClick={e => props.onClickSrcOpener(row.TaskID, row.StudyParadigmCRFID, row.ReadAssignmentID, 'review', row.ReadStage, row.ProcedureID)} className="btn btn-sm btn-success"> Review </button>
                        : <button onClick={e => props.onClickSrcOpener(row.TaskID, row.StudyParadigmCRFID, row.ReadAssignmentID, 'read', row.ReadStage, row.ProcedureID)} className="btn btn-sm btn-success"> Read </button>}
                </div>
            )
        },
        {
            name: "TaskID",
            selector: row => row.TaskID,
            cellExport: row => row.TaskID,
            sortable: true,
            center: true,
            width: "7%"
        },
        {
            name: "StudyParadigmCRFID",
            selector: row => row.StudyParadigmCRFID,
            cellExport: row => row.StudyParadigmCRFID,

            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Study",
            selector: row => row.Study,
            cellExport: row => row.Study,
            sortable: true,
            center: true,
            width: "17%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Study}</div>
            )
        },
        {
            name: "Read Type",
            selector: row => row.ReadType,
            cellExport: row => row.ReadType,
            sortable: true,
            center: true,
            width: "6%"
        },
        {
            name: "Procedure ID",
            selector: row => row.ProcedureID,
            cellExport: row => row.ProcedureID,
            sortable: true,
            center: true,
            width: "13%"
        },
        {
            name: "Assigned Date",
            selector: row => row.AssignedDate,
            cellExport: row => row.AssignedDate,
            sortable: true,
            width: "10%",
            center: true,
            cell: (row) => (
                <div className={classes.textWrap}>{row.AssignedDate}</div>
            ),
            sortFunction: (a, b) => { return props.sortByDateTime(a.AssignedDate, b.AssignedDate); }
        },
        {
            name: "Due",
            selector: row => row.DueDate,
            cellExport: row => row.DueDate,
            sortable: true,
            width: "10%",
            center: true,
            omit: true,
            cell: (row) => (
                <div className={classes.textWrap}>{row.DueDate}</div>
            )
        },
        {
            name: "Hours Remaining",
            selector: row => row.HrsRemaining,
            cellExport: row => row.HrsRemaining,
            sortable: true,
            width: "0%",
            center: true,
            omit: true
        },
        {
            name: "Priority",
            selector: row => row.Priority,
            cellExport: row => row.Priority,
            center: true,
            width: "7%"
        },
        {
            name: "Disease",
            selector: row => row.Disease,
            cellExport: row => row.Disease,
            width: "9%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Disease}</div>
            )
        },
        {
            name: "Info",
            selector: row => row.Info,
            cellExport: row => row.Info,
            center: true,
            width: "14%",
            cell: (row) => (
                <div className={classes.textWrap}>
                    <button onClick={() => OpenHistoWindowPopup(row.URL, event)} type="button" class="btn btn-link">{row.URL}</button> 
                    <p>{row.Info}</p>
                </div>
            )
        }
    ];

    const OpenHistoWindowPopup = (url, event) => {
        objHistoWindow = window.open(url, 'infoWindow', 'resizable=1,top=200,left=450')
    };

    const fetchReadsAssigned = () => {
        fetch('api/ReadsAssigned/GetReadsAssigned')
            .then(response => response.json())
            .then(data => {
                fetchReadTypeAndDisease(data);
                setReadsAssigned(data);
            });
    }

    const fetchReadTypeAndDisease = (data) => {
        let arrD = new Array();
        let arrRead = new Array();
        for (var iCount = 0; iCount < data.length; iCount++) {
            if (arrD.filter(d => d.Value === data[iCount].Disease).length == 0) {
                arrD.push({ Name: data[iCount].Disease, Value: data[iCount].Disease });
            }

            if (arrRead.filter(d => d.Value === data[iCount].ReadType).length == 0) {
                arrRead.push({ Name: data[iCount].ReadType, Value: data[iCount].ReadType });
            }
        }

        arrD = arrD.sort((a, b) => a.Name.localeCompare(b.Name))
        arrD.splice(0, 0, { Name: 'All (Default)', Value: 'All' });
        arrRead.sort((a, b) => a.Name.localeCompare(b.Name))
        arrRead.splice(0, 0, { Name: 'All (Default)', Value: 'All' });

        setArrDisease(arrD);
        setArrReadType(arrRead);
    }


    useEffect(() => {
        fetchReadsAssigned();
    }, []);   

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != 'All') {
            return rows.filter((row) => columns.some(
                (column) => row[column] != null && row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1))
        }
        else
            return rows;
    }
    const readTypeChangeHandler = (e) => {       
        setQ(e.target.value);  
        setInputVal("");        
        document.getElementById("ddlDisease").selectedIndex  = 0;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        document.getElementById("ddlDisease").selectedIndex = 0;
        document.getElementById("ddlReadyType").selectedIndex = 0;
    }
    const diseaseChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal("");
        document.getElementById("ddlReadyType").selectedIndex = 0;
    }
    const conditionalRowStyles = [
        {
            when: row => row.Priority === 'Y',
            style: {               
                color: 'Red'              
            },
        }       
    ];
   
    return (
        <Card>
            <Row className={classes.gridSearchPanel}>
                <Col md={3}>
                    <div className="input-group" >
                        <label style={{ padding: "10px" }}> Read Type:</label>
                        <SelectList id='ddlReadyType' dataSource={arrReadType} onChangeHandler={readTypeChangeHandler} style={{ width: "200px" }} />
                    </div>
                </Col>
                <Col md={3} className="form-inline" ><div className="input-group" >
                    <label style={{ padding: "10px" }}>Disease:</label>
                    <SelectList id='ddlDisease' dataSource={arrDisease} onChangeHandler={diseaseChangeHandler} style={{ width: "200px" }} />
                </div>
                </Col>
                <Col md={6} className="form-inline" >
                    <div style={{ float: "right" }}>
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} style={{ width: "400px" }} placeholder="Search" />
                    </div>
                </Col>
            </Row>
            <div className="tableContainer" style={{ "border": "1px" }}>
                {search(readsAssigned).length > 0 ? <DataTable
                        columns={columns}
                        data={search(readsAssigned)}
                        className="table table-striped table-bordered table-hover"
                        conditionalRowStyles={conditionalRowStyles}
                        customStyles={customStyles}
                        striped
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[20, 50, 100]}
                    /> : <NoData headers={columns}></NoData> }
            </div>
        </Card>
    );
};

export default DashboardHisto;
