import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';

const CRSiteAffiliationRpt = (props) => {
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const colum = new Array();
    const [filteredColumns, setfilteredColumns] = useState([]);
    const [fileName, setFileName] = useState('SiteUserDashboard');
    const [studyList, setStudyList] = useState([]);
    const [studyID, setStudyID] = useState('');
   
    function search(rows) {
        let selString = inputVal.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1) {
                    if (row[column] != null) {
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    }
                }
                else {
                    if (row[column] != null) {
                        return row[column].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1
                    }
                }
            })
        })
    }

    const searchChangeHandler = (e) => {
        setInputVal(e.target.value);
    }  
    
    const fetchCRSiteAffData = () => {
        let searchColumn = new Array();
        fetch('api/ImageService/GetCRSiteAffReport?StudyID=' + studyID)
            .then(response => response.json())
            .then(data => {
                for (let column in data[0]) {
                    colum.push({ name: column, selector: row => row[column], cellExport: row => row[column], sortable: true, center: true });
                    searchColumn.push(column);
                }
                setColumns(colum);
                setRows(data);
                setfilteredColumns(searchColumn);
            });
    }
    const onStudyChangeHandler = (e) => {
        setInputVal("");
        setStudyID(e.target.value);
    }
    const fetchStudies = async () => {
        fetch('api/Site/GetStudiesByUser')
            .then(response => response.json())
            .then(data => {
                setStudyList(data);              
                var selStudyID = document.getElementById("ddlStudies").value;
                setStudyID(selStudyID);               
                fetchCRSiteAffData();
            });
    }
    useEffect(() => {
        fetchStudies();
    }, [studyID]);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <SelectList id='ddlStudies' dataSource={studyList} onChangeHandler={onStudyChangeHandler} style={{ width: "300px" }} />
            </Col>
            <Col md={1} style={{ width: '60px' }}></Col>
            <Col md={2}>
            </Col>
            <Col md={3}></Col>
            <Col>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search"/>
                </div>
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ?
                <DataTableExtensions columns={columns} data={search(rowData)} filter={false} exportHeaders={true} fileName={fileName}>
                <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
                />
                </DataTableExtensions> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
};

export default CRSiteAffiliationRpt;
