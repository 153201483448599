import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation }from 'react-router-dom'
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Button from '..//UI/Button/Button';
import InputString from '../UI/Control/InputString';
import InputTextarea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import Card from '../UI/Card/Card';
import classes from '../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import HTMLEditor from '../UI/HTMLEditor/HTMLEditor';

const OutcomeEmailDesigner = (props) => {
    const [currentEditorContent, setCurrentEditorContent] = useState("<p></p>\n");
    const [modifiedEditorContent, setModifiedEditorContent] = useState('<p></p>\n');
    const [selQuesHeader, setSelQuesHeader] = useState('');
    const [Mappings, setMappings] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { studyId } = location.state;
    const { studyProtocolId } = location.state;
    const [quesHrds, setQuesHrds] = useState('');
    const { isOCReadOnly } = location.state;
    const fetchOutcomeMappings = () => {
        fetch('api/OutcomeNotification/GetOutcomeMappings?StudyID=' + studyId)
                .then(response => response.json())
                .then(data => {
                    setMappings(data);
                });
    }
    const fetchOutcomeQuesHrd = () => {
        let outmappid = document.getElementById('selMappings').value;
        fetch('api/OutcomeNotification/GetOutcomeQuesHeard?StudyID=' + studyId + '&OutMappID=' + outmappid)
            .then(response => response.json())
            .then(data => {
                setQuesHrds(data);
            });
    }
   
    const fetchOutcomeEmailDetails = () => {
        if (document.getElementById('selMappings').value != '') {
            let outmappid = document.getElementById('selMappings').value;
            fetch('api/OutcomeNotification/GetOutcomeEmailDetails?MappingId=' + outmappid)
                .then(response => response.json())
                .then(data => {
                    setData(data);                    
                });
        }
        else {
            document.getElementById('txtSubject').value = '';
            document.getElementById('txtDescription').value = '';
            setCurrentEditorContent("<p></p>\n");
        }
    };

    function setData(data) {
        
        if (data.NotifyContentID > 0) {
            document.getElementById('hdnContentId').value = data.NotifyContentID;
            document.getElementById('txtSubject').value = data.Subject;
            document.getElementById('txtTempName').value = data.TempName;
            document.getElementById('txtDescription').value = data.Description;
            setCurrentEditorContent(data.NotifyContent);
            setModifiedEditorContent(data.NotifyContent)
        }
        else {
            document.getElementById('hdnContentId').value = 0;
            document.getElementById('txtSubject').value = '';
            document.getElementById('txtTempName').value = '';
            document.getElementById('txtDescription').value = '';
            setCurrentEditorContent("<p></p>\n");
        }
    }

    const onSelMappingsChangeHandler = (e) => {       
        fetchOutcomeEmailDetails();
        fetchOutcomeQuesHrd();
    };
    const onQuesHeadersChangeHandler = (e) => {
        let queshrd = document.getElementById("outQuestHrd");
        var selectedText = queshrd.options[queshrd.selectedIndex].text;
        setSelQuesHeader(selectedText);
    }

    function ValidateEmailContent() {
        const objEmailType = document.getElementById('selMappings');
        if (objEmailType.value == '-1') {
            alert('Please select Outcome mapping.');
            objEmailType.focus();
            return false;
        }

        const objSubject = document.getElementById('txtSubject');
        if (objSubject.value.trim() == '') {
            alert('Subject should not be empty.');
            objSubject.focus();
            return false;
        }

        //const objTempName = document.getElementById('txtTempName');
        //if (objTempName.value.trim() == '') {
        //    alert('Template name should not be empty.');
        //    objTempName.focus();
        //    return false;
        //}

        if (modifiedEditorContent == '<p></p>\n') {
            alert('Email content should not be empty.');
            return false;
        }
        return true;
    }

    const SubmitEmailContent = (event) => {
        SaveEmailContent();
    }

    const SaveEmailContent = () => {
        const retVal = ValidateEmailContent();
        if (retVal == false)
            return false;

        let objTempName = document.getElementById('txtTempName');
        let emailContentDesignerModel =
        {
            TempName: objTempName.value,
            NotifyContentID: document.getElementById('hdnContentId').value,
            Subject: document.getElementById('txtSubject').value,
            Description: document.getElementById('txtDescription').value,
            MappingId: document.getElementById('selMappings').value,
            NotifyContent: modifiedEditorContent
        }

        fetch('api/OutcomeNotification/SaveOutcomeNotifyContent', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(emailContentDesignerModel)
        }).then(r => r.json()).then(res => {
            if (res == 0 || res == -1) {
                alert('Notification content not saved, Something went wrong.');
                return false;
            }
            
            if (res == -2) {
                alert('Template name "' + objTempName.value + '" already exist, try with different name.');
                objTempName.focus();
                return false;
            }
            if (res > 0) {
                document.getElementById('hdnContentId').value = res;
                alert('Notification content saved successfully.');
            }
        })
        return true;
    };

    const CloseEmailContent = () => {
        navigate('/Designer');
}

    useEffect(() => {
        fetchOutcomeMappings();
}, [Mappings])

    return (
        <Card>
            <Row style={{ marginTop: "15px" }}>
                <Col md={12} className={classes.verticalLine}>
                    <Row>
                        <Col md={8} style={{ marginBottom: "10px", marginRight: "0px", paddingRight: "0px" }}>
                            <Row style={{ marginBottom: "10px" }}>
                                <Col md={2} style={{ paddingRight: "0px", width:"11%" }}>
                                    <label htmlFor="">Mapping:</label><MandatoryLabel></MandatoryLabel>
                                </Col>
                                <Col md={4}>
                                    <input type="hidden" id="hdnContentId" />
                                    <SelectList id="selMappings" dataSource={Mappings} onChangeHandler={onSelMappingsChangeHandler} />
                                </Col>
                                <Col md={2} style={{ paddingRight: "0px", width: "11%" }}>
                                    <label htmlFor="">Subject:</label><MandatoryLabel></MandatoryLabel>
                                </Col>
                                <Col md={4}>
                                    <InputString id="txtSubject" maxlength="150" placeholder="Subject" style={{ display: "inline" }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} style={{ width: "11%" }}>
                                    <label htmlFor="">Description:</label>
                                </Col>
                                <Col md={4}>
                                    <InputTextarea id="txtDescription" rows="3" maxlength="250" placeholder="Description" />
                                </Col>
                                <Col md={2} style={{ paddingRight: "0px", width: "11%", display: "none" }}>
                                    <label htmlFor="">Template name:</label><MandatoryLabel></MandatoryLabel>
                                </Col>
                                <Col md={4}>
                                    <InputString id="txtTempName" maxlength="150" placeholder="Template name" style={{ display: "none" }} />
                                </Col>                               
                            </Row>
                        </Col>
                        <Col md={4} style={{ marginBottom: "10px", marginLeft: "0px", paddingLeft: "0px" }}>
                            <div className="p-3 bg-secondary text-white rounded">   
                                <Row>
                                    <Col md={3}>Variables: </Col>
                                    <Col md={3}>{'<<StudyName>>'}</Col>
                                    <Col md={3}>{'<<STUDYCODE>>'}</Col>
                                    <Col md={3}>{'<<SiteName>>'} </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>{'<SUBJECTID>>'}</Col> 
                                    <Col md={3}>{'<<TASKID>>'}</Col>
                                    <Col md={3}>{'<<VISITNAME>>'} </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>Ques Header: </Col>
                                    <Col md={6}><SelectList id="outQuestHrd" dataSource={quesHrds}
                                        onChangeHandler={onQuesHeadersChangeHandler} style={{ width: "95%" }} /></Col>
                                    <Col md={3}>{selQuesHeader != '' ?`<<${selQuesHeader}>>` :''}</Col>
                                </Row>                                
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "5px"}}>
                        <Col md={1} style={{ width: "100%" }}>Ouctcome Email Designer <MandatoryLabel></MandatoryLabel>:</Col>
                    </Row>
                    <Row>
                        <Col md={12}><HTMLEditor id="txtEmailContent" currentEditorContent={currentEditorContent}
                            modifiedEditorContent={setModifiedEditorContent} /></Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{ "textAlign": "center", marginTop: "10px" }}>
                                {(props.action != "view" && !isOCReadOnly) && <Button id="btnSave" className="btn btn-success" style={{ marginRight: "10px" }} onClick={SubmitEmailContent}> Save </Button>}
                                <Button id="btnCancel" className="btn btn-success" style={{ marginRight: "10px" }} onClick={CloseEmailContent}> Cancel </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default OutcomeEmailDesigner;
