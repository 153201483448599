import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import DataTable from "react-data-table-component";
import Button from '../UI/Button/Button';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import SelectList from '../UI/Control/SelectList';

const OutcomeGlobalEmailList = (props) => {
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [rowData, setData] = useState([]);
    const location = useLocation();
    const { studyId, isOCReadOnly } = location.state;
    //const [arrEmailTemplate, setArrEmailTemplate] = useState([]);
    const [OutcomeMapp, setOutcomeMapp] = useState([]);

    const columns = [
        {
            name: "",
            selector: row => row.GlobEmailID,
            cellExport: row => row.GlobEmailID,
            omit:true
        },
        {
            name: "Email",
            selector: row => row.EmailID,
            cellExport: row => row.EmailID,
            sortable: true,
            center:true,
            style: { justifyContent:"left" },
        },
        {
            name: "Outcome Mapping",
            sortable: true,
            center: true,
            width:"35%",
            cell: (row) => {
                return <div style={{ width: "100%" }}>
                    <SelectList id={"ddlEmailTemplate_" + row.GlobEmailID} value={row.OutcomesMappingID} dataSource={OutcomeMapp} onChangeHandler={() => emailTemplateChangeHandler(row.GlobEmailID, row.EmailID,  event)} style={{ margin: "5px", width: "90%" }} />
                </div>
            }
        },
        {
            name: "Action",
            button: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            width: !isOCReadOnly? "15%": "0",
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {!isOCReadOnly && <Button id="btnDelete" className="btn btn-danger" onClick={() => DeleteGlobalOutcomeEmail(row.GlobEmailID, row.EmailID)}>Delete</Button>}
                </div >
            ),        
        }
    ];

    const fetchEmailTemplate = () => {
        fetch('api/OutcomeNotification/GetOutcomeMappings?StudyID=' + studyId)
            .then(response => response.json())
            .then(data => {
                let arr = [];
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setOutcomeMapp(arr);
            });
    }

    const addEmailHandler = (e) => {

        
        let objEmailTemplate = document.getElementById('ddlEmailTemplate');
        if (objEmailTemplate.value.trim() == '-1') {
            alert('Please select outcome mapping.');
            objEmailTemplate.focus();
            return false;
        }

        let objEmail = document.getElementById('txtEmail');
        if (objEmail.value.trim() == '') {
            alert('Email should not be empty.');
            objEmail.focus();
            return false;
        }

        var re = /\S+@\S+\.\S+/;
        if (!re.test(objEmail.value)) {
            alert('Please provide valid email address.');
            objEmail.focus();
            return false;
        }

        let objGlobalOutcomeEmailsModel = {
            StudyID: studyId,
            EmailID: objEmail.value,
            OutcomesMappingID: document.getElementById('ddlEmailTemplate').value
        }

        fetch('api/OutcomeNotification/SaveGlobalOutcomeEmails', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objGlobalOutcomeEmailsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Email is not saved, Something went wrong.');
                return false;
            }

            if (res == -2) {
                alert('Email is already exist, try with different email.');
                return false;
            }

            if (res > 0) {
                objEmail.value = '';
                fetchData(document.getElementById('ddlEmailTemplate').value);
            }
        })
        return true;
    }

    const fetchData = (mappingId) => {
        fetch('api/OutcomeNotification/GetGlobalOutcomeEmails?studyId=' + studyId + '&mappingId=' + mappingId)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    const DeleteGlobalOutcomeEmail = (globEmailID, emailId) => {
        var con = confirm('Are you sure, you want to delete "' + emailId + '" email?');
        if (con == true) {
            let objGlobalOutcomeEmailsModel = {
                StudyID: studyId,
                GlobEmailID: globEmailID
            }

            fetch('api/OutcomeNotification/DeleteGlobalOutcomeEmail', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objGlobalOutcomeEmailsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1) {
                    alert('Email is not deleted, Something went wrong.');
                    return false;
                }

                if (res > 0) {
                    fetchData(document.getElementById('ddlEmailTemplate').value);
                }
            })
            return true;
        }
    };

    const emailTemplateChangeHandler = (globEmailID, emailId, e) => {
        let objMapping = document.getElementById(e.target.id);
        let OutcomesMappingID = objMapping.value;
        var mappingName = objMapping.options[objMapping.selectedIndex].text;
        AssignTemplateToEmail(globEmailID, OutcomesMappingID, emailId, mappingName);
        };

    const AssignTemplateToEmail = (globEmailID, OutcomesMappingID, emailId, mappingName) => {
            let objGlobalOutcomeEmailsModel = {
                OutcomesMappingID: OutcomesMappingID,
                GlobEmailID: globEmailID
            }

        fetch('api/OutcomeNotification/AssignEmailTempToGOEmail', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objGlobalOutcomeEmailsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1) {
                    alert('Assign email template to global outcome email not done, Something went wrong.');
                    return false;
                }

                if (res == -2) {
                    alert(emailId + ' mail is already assigned to "' + mappingName+'" mapping, try with different email.');
                    return false;
                }

                if (res > 0) {
                    fetchData(document.getElementById('ddlEmailTemplate').value);
                }
            })
            return true;
    };

    const filteredColumns = ['EmailID'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
    }

    const cancelOutcomeGlobalEmail = () => {
        navigate('/Designer');
    }

    useEffect(() => {
        fetchEmailTemplate();
        fetchData(-1);
    }, [])

    var outcomingMappingHandler = (e) => {
        fetchData(e.target.value);
    };

    return (
        <div className="container">
        <div className="row">
            <div className="col-sm-1" style={{ width: "4%" }}>
            </div>
                <div className="col-sm-10" style={{ width: "95%" }}>
                    <div className="row">
                        <div className="col-sm-2">
                            <label className="mb-1">Outcome mapping:</label>
                            <MandatoryLabel></MandatoryLabel>:
                        </div>
                        <div className="col-sm-3">
                            <SelectList id="ddlEmailTemplate" dataSource={OutcomeMapp} onChangeHandler={() => outcomingMappingHandler(event)} style={{ marginBottom: "5px" }} />
                        </div>
                   </div>
                <div className="row">
                        {!isOCReadOnly && <>
                        <div className="col-sm-2">
                                <label>Email</label>
                                <MandatoryLabel></MandatoryLabel>:
                            </div>
                        <div className="col-sm-3">
                            <InputString id="txtEmail" maxlength="255"></InputString>
                        </div>
                        <div className="col-sm-1">
                            <Button id="btnAdd" className="btn btn-md btn-success" variant="primary" onClick={addEmailHandler} style={{ float: "left" }}>Add </Button>
                            </div></>}
                        {isOCReadOnly && <div className="col-sm-6"> </div>}
                        <div className="col-sm-4">
                            <InputString id="txtSearch" onChangeHandler={searchChangeHandler} placeholder="Search" />
                        </div>
                        <div className="col-sm-2" style={{ verticalAlign:"center" }}>
                            <Button variant="primary" className="btn btn-success" style={{ float: "right", marginRight: "5px"}} onClick={cancelOutcomeGlobalEmail}>Return To Dashboard</Button>
                        </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                    <div className="tableContainer">
                        {search(rowData).length > 0 ? <DataTable
                            columns={columns}
                            data={search(rowData)}
                            className="table table-striped table-bordered table-hover"
                            customStyles={customStyles}
                            striped
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[20, 50, 100]}
                        /> : <NoData headers={columns}></NoData>}
                    </div>
                </div>
            </div>
            <div className="col-sm-1" style={{ width: "4%" }}>
            </div>
            </div>
        </div>
    );
}

export default OutcomeGlobalEmailList;