import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputTextarea from '../UI/Control/InputTextarea';
import classes from './../TrialstatNoto.module.css';
import { format } from 'date-fns';
import EditIcon from '../../Images/Icons/edit.svg';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import SelectList from '../UI/Control/SelectList';
import InputContainer from '../UI/Container/InputContainer';

const EmailDetailsModal = (props) => {
    const [usrRole, ] = useState('');
    const [show, setShow] = useState(false);
    const [emailLog, setEmailLog] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchGetEmailLogs();
        setShow(true);
    }

    const fetchGetEmailLogs = () => {
        fetch('api/ImageService/getEmailLogs?taskId=' + props.taskId + '&taskActionLogID=' + props.taskActionLogID)
            .then(response => response.json())
            .then(data => {
                setEmailLog(data);
            });
    }

    useEffect(() => {
        for (var cnt = 0; cnt < emailLog.length; cnt++) {
            if (document.getElementById('divBodyText_' + emailLog[cnt].StrReadAssignmentIDs) != null)
                document.getElementById('divBodyText_' + emailLog[cnt].StrReadAssignmentIDs).innerHTML = emailLog[cnt].BodyText;
        }
    }, [emailLog]);

    return (
        <>
            <button type="button" onClick={handleShow} class="btn btn-link">{props.text}</button>      
           <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Sixteen} backdrop="static">
                <Modal.Body style={{ maxHeight: "600px", overflowY: "scroll" }}>
                    {emailLog.map((item, index) => {
                        return (
                            <div style={{ marginBottom:"10px" }}>
                                <InputContainer Expended='true' header={"Email Log : " + item.Status} style={{ textAlign: "left", marginBottom: "0px", backgroundColor: item.Status.toLowerCase() == 'fail' ? "orange" : ''  }}>
                                <div style={{ marginLeft: "12px", marginRight: "12px", marginTop: "0px" }}>
                                <div key={index}>
                                <Row style={{ "height": "40px" }}>
                                    <Col className={classes.cellBorder} style={{ "display": "inline-flex", borderRight: "none" }} md={4}>
                                        <div style={{ marginRight: "50px" }}><b>Task ID:</b></div>
                                        {item.TaskID}
                                    </Col>
                                    <Col className={classes.cellBorder} style={{ "display": "inline-flex", borderLeft: "none" }}>
                                        <div style={{ marginLeft: "150px", marginRight: "50px" }}><b>Read Assignment ID:</b></div>
                                        {item.StrReadAssignmentIDs}
                                    </Col>
                                </Row>
                                    <Row style={{ "height": "40px" }}> 
                                        <Col className={classes.cellBorder} style={{ "display": "none", borderRight: "none" }} md={4}>
                                            <div style={{ marginRight: "50px" }}><b>From:</b></div>
                                            {item.FromEmailID}
                                        </Col>
                                    <Col className={classes.cellBorder} style={{ "display": "inline-flex", borderLeft: "none" }}>
                                        <div style={{ marginLeft: "0px", marginRight: "50px"}}><b>To:</b></div>
                                        {item.ToEamilID}
                                    </Col>                                   
                                </Row>
                                <Row style={{ minHeight: "40px", "height": "auto" }}>
                                    <Col className={classes.cellRLBBorder} md={3}>Assigned Reader</Col>
                                    <Col className={classes.cellRightBorder}>{item.AssignedReader}</Col>
                                </Row>
                                <Row style={{ minHeight: "40px", "height": "auto" }}>
                                    <Col className={classes.cellRLBBorder} md={3}>Subject</Col>
                                    <Col className={classes.cellRightBorder}>{item.Subject}</Col>
                                </Row>
                                            {item.Status.toLowerCase() == 'success' && <Row style={{ minHeight: "40px", "height": "auto" }}>
                                    <Col className={classes.cellRLBBorder} md={3}>Email content</Col>
                                    <Col id={"divBodyText_" + item.StrReadAssignmentIDs} className={classes.cellRightBorder}>{}</Col>
                                            </Row>}
                                    {item.Status.toLowerCase() == 'fail' && <Row style={{ minHeight: "40px", "height": "auto" }}>
                                    <Col className={classes.cellRLBBorder} md={3}>Error Message</Col>
                                    <Col className={classes.cellRightBorder}>{item.ErrorMsg}</Col>
                                </Row>}
                            </div>
                                </div>
                          
                        </InputContainer>
                            </div>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default EmailDetailsModal;