import React, { useState, useEffect } from 'react';
import Container from './UI/Container/Container';
import Row from './UI/Container/Row';
import InputUsername from './UI/Control/InputUsername';
import InputPassword from './UI/Control/InputPassword';
import SelectList from './UI/Control/SelectList';
import NotoLogo from './NotoLogo';
import MandatoryLable from './UI/Control/MandatoryLabel';

const Login = (props) => {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState();
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState();
  const [formIsValid, setFormIsValid] = useState(false);
    const [forgetPwsURL, setForgetPwsURL] = useState('');

    useEffect(() => {
        sessionStorage.setItem('searchedVal', '');
        sessionStorage.setItem('closedTaskSearchedVal', '');
        sessionStorage.setItem('searchedStudyVal', '');
        localStorage.removeItem('ReadOpened');
        edcAPIURL();
        const timer = setTimeout(() => {
            setFormIsValid(
                enteredEmail.includes('@') && enteredPassword.trim().length > 6
            );
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [enteredEmail, enteredPassword]);

    //Service call sample code, tej kumar
    //const fetchEDCProject = async () => {
    //    const response = await fetch('api/ServiceCall/CreateStudy',
    //        {
    //            method: "POST",
    //            mode:'cors'
    //        }
    //    )
    //    if (!response.ok) {
    //        throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
    //    }
    //    const edcProject = await response.text();
    //    console.log('EDC Forms: '+edcProject)
    //}
    //fetchEDCProject().catch((error) => {
    //    console.log(error.message)
    //})

    const getUserRole = (usrName, passWrd) => {
        fetch("api/Users/GetRoleByUserName?userName=" + usrName)
            .then(response => response.text())
            .then(data => {
                if (data != "") {
                    props.onLogin(usrName, passWrd, data);
                }
                else {
                    if (usrName.toLowerCase() == 'root')
                        props.onLogin(usrName, passWrd, 'Database Designer');
                    else
                        alert('There is no assigned role; please contact system administration.')
                }
            })
    }
     const edcAPIURL = () => {
        fetch("api/AppSettings/EDCForgetPassURL")
            .then(response => response.text())
            .then(data => {              
                setForgetPwsURL(data)
            })
    }
    const submitHandler = (event) => {
        event.preventDefault();
        localStorage.removeItem('isFisrtTimeLoading');
        let usrName = document.getElementById("txtUserName").value;
        let passWrd = document.getElementById("txtPassword").value;       

        if (usrName === "") {
            alert('Please provide User Name.')
            document.getElementById("txtUserName").focus();
            return false;
        }
        if (passWrd === "") {
            alert('Please provide Password.')
            document.getElementById("txtPassword").focus();
            return false;
        }
        getUserRole(usrName, passWrd);        
    };
    const userType = [
        {
        Name:"--Select User Type---",
        Value:"SUT"
        },
        {
            Name:"NOTO Designer",
            Value:"ND"
        },
         {
            Name:"Central Reader-Endo",
            Value:"CR-EN"
        },
        {
            Name: "Central Reader-Histo",
            Value: "CR-HIS"
        },
          {
            Name:"Image Service Team",
            Value:"IST"
        },
        {
            Name: "Noto Admin",
            Value: "ADMIN"
        }
    ]
    return (
        <Container>
            <form onSubmit={submitHandler}>
                <Row> <div style={{"height":"100px"}}></div> </Row>
                <Row>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8" style={{ backgroundColor: "aliceblue", "padding" : "80px" }}>                      
                        <Row>
                            <div className="col-lg-0"></div>
                            <div className="col-lg-12">
                                <Row>                                    
                                    <div className="col-sm-12">
                                        <NotoLogo></NotoLogo>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-lg-12" style={{
                                        backgroundColor: "#304780", color: "#FFFFFF",
                                        font: "Arial,Helvetica,Geneva,Swiss,SunSans-Regular", fontSize: "20px", paddingLeft: "6px", paddingRight: "6px"
                                    }}>
                                        Please enter your user name and password to access your account
                                    </div>                                   
                                </Row>
                                <Row>                                    
                                    <div className="col-lg-12" style={{ backgroundColor: "#E3E5E5", padding: "10px", paddingRight: "0px"}}>
                                        <Row>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-10" style={{display:"flex"}}>
                                                <InputUsername></InputUsername>
                                                <MandatoryLable />
                                            </div>
                                            <div className="col-lg-1"></div>
                                        </Row>
                                        <Row>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-10" style={{ "display": "flex" }}>
                                                <InputPassword style={{margin:"5px"}} placeholder="Password"> </InputPassword>    
                                                <MandatoryLable />
                                            </div>
                                            <div className="col-lg-1"></div>
                                        </Row>
                                        <div style={{ "display": "none" }}>
                                            <Row >
                                                <div className="col-lg-1"></div>
                                                <div className="col-lg-10" style={{ "display": "flex" }}>
                                                    <SelectList id="userType" dataSource={userType} style={{ margin: "5px" }}></SelectList>  
                                                    <MandatoryLable />
                                                </div>
                                                <div className="col-lg-1"></div>
                                                </Row>  
                                        </div>
                                        <Row>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-10" style={{ "display": "flex" }}>
                                                <div style={{ "padding": "6px" }}>
                                                    <a href={forgetPwsURL} style={{ marginLeft: "5px", fontSize: "15px" }}>
                                                        Forgot your password?
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-1"></div>
                                        </Row>       
                                    </div>
                                    <div className="col-lg-0"></div>
                                </Row>
                                <Row>
                                    <div className="col-lg-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#304780", height:"45px" }}>
                                        <input id="btnLogin" type="submit" className="btn btn-success" value="LOGIN" style={{ width: "80%" }} />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-lg-0"></div>
                                    <div className="col-lg-12" style={{ paddingLeft: "6px", fontSize: "12px" }}>
                                        Copyright&#169; 2001-{new Date().getFullYear()}, TrialStat&#174; Solutions Inc. All rights reserved
                                    </div>
                                    <div className="col-lg-0"></div>
                                </Row>
                            </div>
                            <div className="col-lg-0"></div>
                        </Row>
                    </div>
                    <div className="col-lg-2"></div>
                </Row>
      </form>
   </Container>
  );
};
export default Login;
