import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Disable } from 'react-disable';
import Card from '../UI/Card/Card';
import InputNumber from '../UI/Control/InputNumber';
import classes from './../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import SelectList from '../UI/Control/SelectList';

const VisitSettings = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    let NoOfGroup = 0;
    let NoOfVisit = 0;
    let groupItems = [];
    let visitItems = [];
    let arrGroupNames = [];
    let arrVisitNames = [];

    const studyProtocolID = useSelector(state => state.globaldata.gblDt01);

    const [items, setItems] = useState([]);
    const [vItems, setVisitItems] = useState([]);
    const [groupNames, setGroupNames] = useState([]);
    const [GroupCnt, setNoOfGroup] = useState(1);
    const [VisitCnt, setNoOfVisit] = useState(1);
    const [studyGroupId, setStudyGroupID] = useState(0);
    const [itemsTemp, setItemsTemp] = useState([]);
    const [vItemsTemp, setVisitItemsTemp] = useState([]);
    const [visitCategoryList, setVisitCategoryList] = useState([{}]);

    const NoOfGroupChangeHandler = () => {
        arrGroupNames = [];
        let objGrp = document.getElementById('txtNoOfGroup');
        if (objGrp.value < 1)
            return false;
        
        if (objGrp.value != '') {
            NoOfGroup = objGrp.value.replace(/\D/g, "");
        }
        
        
        if (NoOfGroup == 1) {
            document.getElementById("divGroupList").style.display = "none";
       }
        else {
            document.getElementById("divGroupList").style.display = "block";
           
        }
        SetVisitItemsData();
        setItems(groupItems);
    };

    const NoOfVisitChangeHandler = () => {
        let objVst = document.getElementById('txtNoOfVisit');
        if (objVst.value < 1)
            return false;

        if (ValidateGroups() == true) {
            SetVisitItemsData();
        }
    };

    function ValidateGroups() {
        arrGroupNames = [];
        if (GroupCnt == 0) {
            let objNoOfGroup = document.getElementById('txtNoOfGroup');
            alert('Number of cohorts/groups should not be empty.');
            objNoOfGroup.focus();
            return false;
        }

        var objGroups = document.getElementsByName("txtGroup");
        for (var iCount = 0; iCount < objGroups.length; iCount++) {
            arrGroupNames.push({ StudyGroupID: document.getElementById('hiddenGroup_' + iCount).value, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value });
        }

        if (arrGroupNames.length > 1) {
            for (var iCount = 0; iCount < arrGroupNames.length; iCount++) {
                var grpName = arrGroupNames[iCount].GroupName;
                if (grpName == '') {
                    alert('Group ' + (iCount + 1) + ' name should not be empty');
                    document.getElementById('txtGroupName_' + iCount).focus();
                    return false;
                }

                var groupShort = arrGroupNames[iCount].GroupShort;
                if (groupShort == '') {
                    alert('Group short name should not be empty for group name' + (iCount + 1));
                    document.getElementById('txtGroupShortName_' + iCount).focus();
                    return false;
                }
            }
        }
        return true;
    }

    function SetVisitItemsData() {
        let obj = document.getElementById('txtNoOfVisit');
        if (obj.value != '') {
            NoOfVisit = obj.value.replace(/\D/g, "");
            setNoOfVisit(NoOfVisit);
        }

        let objGrp = document.getElementById('txtNoOfGroup');
        if (objGrp.value != '') {
            NoOfGroup = objGrp.value.replace(/\D/g, "");
             setNoOfGroup(NoOfGroup);
        }

            if (NoOfGroup > 1 && itemsTemp.length == 0) {
                for (var iCount = 0; iCount < NoOfGroup; iCount++) {
                    if (itemsTemp.length == 0) {
                        document.getElementById("divGroupList").style.display = "block";
                        if (document.getElementById('txtGroupName_' + iCount) != null) {
                            groupItems.push({ StudyGroupID: 0, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value });
                        }
                        else {
                            groupItems.push({ StudyGroupID: 0, GroupName: '', GroupShort: '' });
                        }
                    }
                    else {
                        groupItems.push({ StudyGroupID: itemsTemp[iCount].StudyGroupID, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value });
                    }
                }
            }
            else {
                let cnt = 0;
                if (NoOfGroup > itemsTemp.length) {
                    for (var iCount = 0; iCount < itemsTemp.length; iCount++) {
                        if (document.getElementById('txtGroupName_' + cnt) != null) {
                            groupItems.push({ StudyGroupID: itemsTemp[iCount].StudyGroupID, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value });
                        }
                        else {
                            groupItems.push({ StudyGroupID: itemsTemp[iCount].StudyGroupID, GroupName: itemsTemp[iCount].GroupName, GroupShort: itemsTemp[iCount].GroupShort });

                        }
                        cnt++;
                    }

                    let noOfGroupTemp = (NoOfGroup - itemsTemp.length);
                    for (var iCount = 0; iCount < noOfGroupTemp; iCount++) {
                        if (document.getElementById('txtGroupName_' + cnt) != null) {
                            groupItems.push({ StudyGroupID: document.getElementById('hiddenGroup_' + cnt).value, GroupName: document.getElementById('txtGroupName_' + cnt).value, GroupShort: document.getElementById('txtGroupShortName_' + cnt).value });
                        }
                        else {
                            groupItems.push({ StudyGroupID: 0, GroupName: '', GroupShort: '' });
                        }
                        cnt++;
                    }
                }
                
                if (NoOfGroup == itemsTemp.length) {
                    for (var iCount = 0; iCount < itemsTemp.length; iCount++) {
                        if (document.getElementById('txtGroupName_' + iCount) != null) {
                            groupItems.push({ StudyGroupID: itemsTemp[iCount].StudyGroupID, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value });
                        }
                        else {
                            groupItems.push({ StudyGroupID: itemsTemp[iCount].StudyGroupID, GroupName: itemsTemp[iCount].GroupName, GroupShort: itemsTemp[iCount].GroupShort });
                        }
                    }
                }

                if (NoOfGroup < itemsTemp.length) {
                    for (var iCount = 0; iCount < NoOfGroup; iCount++) {
                        if (document.getElementById('txtGroupName_' + iCount) != null) {
                            groupItems.push({ StudyGroupID: itemsTemp[iCount].StudyGroupID, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value });
                        }
                        else {
                            groupItems.push({ StudyGroupID: itemsTemp[iCount].StudyGroupID, GroupName: itemsTemp[iCount].GroupName, GroupShort: itemsTemp[iCount].GroupShort });
                        }
                    }
                }
            }

        setItems(groupItems);

        if (vItemsTemp.length == 0) {
            for (var iCount = 0; iCount < NoOfVisit; iCount++) {
                document.getElementById("divVisitList").style.display = "block";
                visitItems.push({ StudyVisitID: 0, VisitName: '', VisitShort: '', VisitCategoryID: -1, HiddenDefault: 'N', listGroupVisit: new Array() });
                for (var jCount = 0; jCount < groupItems.length; jCount++) {
                    if (groupItems[jCount].GroupName != '' && groupItems.length > 1)
                        visitItems[iCount].listGroupVisit.push({ StudyGVID: groupItems[jCount].StudyGVID, StudyGroupID: groupItems[jCount].StudyGroupID, GroupName: groupItems[jCount].GroupName, IncludeVisit: 'N' });
                }
            }
        }
        else {
            if (NoOfVisit > vItemsTemp.length) {
                for (var iCount = 0; iCount < vItemsTemp.length; iCount++) {
                    document.getElementById("divVisitList").style.display = "block";
                    visitItems.push({ StudyVisitID: vItemsTemp[iCount].StudyVisitID, VisitName: vItemsTemp[iCount].VisitName, VisitShort: vItemsTemp[iCount].VisitShort, VisitCategoryID: vItemsTemp[iCount].VisitCategoryID, HiddenDefault: vItemsTemp[iCount].HiddenDefault, listGroupVisit: new Array() });
                    for (var jCount = 0; jCount < groupItems.length; jCount++) {
                        let filtered = vItemsTemp[iCount].listGroupVisit.filter(item => {
                            return (item.StudyVisitID == vItemsTemp[iCount].StudyVisitID && item.StudyGroupID == groupItems[jCount].StudyGroupID);
                        });
                        if (groupItems[jCount].GroupName != '' && groupItems.length > 1) 
                            visitItems[iCount].listGroupVisit.push({ StudyGVID: groupItems[jCount].StudyGVID, StudyGroupID: groupItems[jCount].StudyGroupID, GroupName: groupItems[jCount].GroupName, IncludeVisit: filtered.length > 0 ? filtered[0].IncludeVisit : 'N' });
                    }
                }

                let noOfVisitTemp = (NoOfVisit - vItemsTemp.length);
                let iCountTemp = vItemsTemp.length;
                for (var iCount = iCountTemp; iCount < (noOfVisitTemp + iCountTemp); iCount++) {
                    document.getElementById("divVisitList").style.display = "block";
                    visitItems.push({ StudyVisitID: 0, VisitName: '', VisitShort: '', HiddenDefault: 'N', listGroupVisit: new Array() });
                    for (var jCount = 0; jCount < groupItems.length; jCount++) {
                        if (groupItems[jCount].GroupName != '' && groupItems.length > 1)
                            visitItems[iCount].listGroupVisit.push({ StudyGVID: groupItems[jCount].StudyGVID, StudyGroupID: groupItems[jCount].StudyGroupID, GroupName: groupItems[jCount].GroupName, IncludeVisit: 'N' });
                    }
                }
            }

            if (NoOfVisit == vItemsTemp.length) {
                for (var iCount = 0; iCount < vItemsTemp.length; iCount++) {
                    document.getElementById("divVisitList").style.display = "block";
                    visitItems.push({ StudyVisitID: vItemsTemp[iCount].StudyVisitID, VisitName: vItemsTemp[iCount].VisitName, VisitShort: vItemsTemp[iCount].VisitShort, VisitCategoryID: vItemsTemp[iCount].VisitCategoryID, HiddenDefault: vItemsTemp[iCount].HiddenDefault, listGroupVisit: new Array() });
                    for (var jCount = 0; jCount < groupItems.length; jCount++) {
                        let filtered = vItemsTemp[iCount].listGroupVisit.filter(item => {
                            return (item.StudyVisitID == vItemsTemp[iCount].StudyVisitID && item.StudyGroupID == groupItems[jCount].StudyGroupID);
                        });
                        if (groupItems[jCount].GroupName != '' && groupItems.length > 1)
                            visitItems[iCount].listGroupVisit.push({ StudyGVID: groupItems[jCount].StudyGVID, StudyGroupID: groupItems[jCount].StudyGroupID, GroupName: groupItems[jCount].GroupName, IncludeVisit: filtered.length > 0 ? filtered[0].IncludeVisit : 'N' });
                    }
                }
            }

            if (NoOfVisit < vItemsTemp.length) {
                for (var iCount = 0; iCount < NoOfVisit; iCount++) {
                    document.getElementById("divVisitList").style.display = "block";
                    visitItems.push({ StudyVisitID: vItemsTemp[iCount].StudyVisitID, VisitName: vItemsTemp[iCount].VisitName, VisitShort: vItemsTemp[iCount].VisitShort, VisitCategoryID: vItemsTemp[iCount].VisitCategoryID, HiddenDefault: vItemsTemp[iCount].HiddenDefault, listGroupVisit: new Array() });
                    for (var jCount = 0; jCount < groupItems.length; jCount++) {
                        let filtered = vItemsTemp[iCount].listGroupVisit.filter(item => {
                            return (item.StudyVisitID == vItemsTemp[iCount].StudyVisitID && item.StudyGroupID == groupItems[jCount].StudyGroupID);
                        });
                        if (groupItems[jCount].GroupName != '' && groupItems.length > 1)
                            visitItems[iCount].listGroupVisit.push({ StudyGVID: groupItems[jCount].StudyGVID, StudyGroupID: groupItems[jCount].StudyGroupID, GroupName: groupItems[jCount].GroupName, IncludeVisit: filtered.length > 0 ? filtered[0].IncludeVisit : 'N' });
                    }
                }
            }
        }

        setVisitItems(visitItems);
    }

    const fetchVisitSettingDetail = () => {
        fetch('api/VisitSetting/GetVisitSettingDetail?StudyProtocolID=' + studyProtocolID)
            .then(response => response.json())
            .then(data => {
                setData(data);
                if (props.userRole.toLowerCase() == 'super user' && data.IsTaskInProd.toLowerCase() == 'yes') {
                    document.getElementById("txtNoOfGroup").disabled = true;
                    document.getElementById("txtNoOfVisit").disabled = true;
                    var arrVisits = document.getElementsByName("txtVisit");
                    for (var iCount = 0; iCount < arrVisits.length; iCount++) {
                        arrVisits[iCount].disabled = true;
                        document.getElementById('txtVisitId_' + iCount).disabled = true;
                        document.getElementById('rdoHiddenByDefaultYes_' + iCount).disabled = true;
                        document.getElementById('rdoHiddenByDefaultNo_' + iCount).disabled = true;
                    }

                    var arrGroups = document.getElementsByName("chkGroup");
                    for (var jCount = 0; jCount < arrGroups.length; jCount++) {
                        arrGroups[jCount].disabled = true;
                    }
                }
                
            });
    }

    function setData(data) {
        if (data.NoOfGroup == 0) {
            //Edit mode : If group is not saved then to set number of groups default value 1.
            setNoOfGroup(1);
        }
        else {
            setNoOfGroup(data.NoOfGroup);
        }
        
        if (data.NoOfVisit == 0) {
            //Edit mode : If visit is not saved then to set number of visit default value 1.
            setNoOfVisit(1);
            SetVisitItemsData();
        }
        else {
            setNoOfVisit(data.NoOfVisit);
        }

        if (data.listGroup.length == 1) {
            for (var iCount = 0; iCount < data.listGroup.length; iCount++) {
                groupItems.push({ StudyGroupID: data.listGroup[iCount].StudyGroupID, GroupName: data.listGroup[iCount].GroupName, GroupShort: data.listGroup[iCount].GroupShort });
            }
            
            setItems(groupItems);
            setItemsTemp(groupItems);
            setStudyGroupID(data.listGroup[0].StudyGroupID);
        }
        if (data.listGroup.length > 1) {
            for (var iCount = 0; iCount < data.listGroup.length; iCount++) {
                document.getElementById("divGroupList").style.display = "block";
                groupItems.push({ StudyGroupID: data.listGroup[iCount].StudyGroupID, GroupName: data.listGroup[iCount].GroupName, GroupShort: data.listGroup[iCount].GroupShort });
            }
            setItems(groupItems);
            setItemsTemp(groupItems);
            setStudyGroupID(data.listGroup[0].StudyGroupID);
        }
        
        for (var iCount = 0; iCount < data.listVisit.length; iCount++) {
            document.getElementById("divVisitList").style.display = "block";
            visitItems.push({ StudyVisitID: data.listVisit[iCount].StudyVisitID, VisitName: data.listVisit[iCount].VisitName, VisitShort: data.listVisit[iCount].VisitShort, VisitCategoryID: data.listVisit[iCount].VisitCategoryID, HiddenDefault: data.listVisit[iCount].HiddenDefault, listGroupVisit: new Array() });
            if (data.listVisit[iCount].listGroupVisit.length > 1) {
                for (var jCount = 0; jCount < data.listVisit[iCount].listGroupVisit.length; jCount++) {
                    visitItems[iCount].listGroupVisit.push({ StudyGVID: data.listVisit[iCount].listGroupVisit[jCount].StudyGVID, StudyGroupID: data.listVisit[iCount].listGroupVisit[jCount].StudyGroupID, GroupName: data.listVisit[iCount].listGroupVisit[jCount].GroupName, IncludeVisit: data.listVisit[iCount].listGroupVisit[jCount].IncludeVisit, StudyVisitID: data.listVisit[iCount].listGroupVisit[jCount].StudyVisitID });
                }
            }
        }

        setVisitItems(visitItems);
        setVisitItemsTemp(visitItems);
    }

    useEffect(() => {
        fetchVisitCategoryList();
        if (props.Action.toLowerCase() === 'edit' || props.Action.toLowerCase() === 'view') {
            fetchVisitSettingDetail();
        }
        else {
            SetVisitItemsData();
        }
    }, [])

    function ValidateStudySettings() {
        if (VisitCnt == 0) {
            let objNoOfVisit = document.getElementById('txtNoOfVisit');
            alert('Number of visits should not be empty.');
            objNoOfVisit.focus();
            return false;
        }

        arrVisitNames = [];
        var obj = document.getElementsByName("txtVisit");
        for (var iCount = 0; iCount < obj.length; iCount++) {
            arrVisitNames.push({ VisitName: document.getElementById('txtVisitName_' + iCount).value, VisitShort: document.getElementById('txtVisitId_' + iCount).value, VisitCategoryID: document.getElementById('ddlVisitCategory_' + iCount).value });
        }

        for (var iCount = 0; iCount < arrVisitNames.length; iCount++) {
            var visitName = arrVisitNames[iCount].VisitName;
            let objVisitName = document.getElementById('txtVisitName_' + iCount);
            if (visitName == '') {
                alert('Visit name should not be empty');
                objVisitName.focus();
                return false;
            }

            if (visitName.toLowerCase() == 'unscheduled' || visitName.toLowerCase() == 'infusion') {
                alert('The Visit name "' + visitName + '" is reserved keyword.  Please try a different name.');
                objVisitName.focus();
                return false;
            }

            var visitShort = arrVisitNames[iCount].VisitShort;
            let objVisitId = document.getElementById('txtVisitId_' + iCount);
            if (visitShort == '') {
                alert('Visit short name should not be empty.');
                objVisitId.focus();
                return false;
            }

            if (visitShort.toLowerCase() == 'unscheduled' || visitShort.toLowerCase() == 'infusion') {
                alert('The Visit short name "' + visitShort + '" is reserved keyword.  Please try a different name.');
                objVisitId.focus();
                return false;
            }

            var visitCategoryId = arrVisitNames[iCount].VisitCategoryID;
            let objVisitCategory = document.getElementById('ddlVisitCategory_' + iCount);
            if (visitCategoryId == -1) {
                alert('Please select visit category for visit name "' + visitName+'" .');
                objVisitCategory.focus();
                return false;
            }

            var valueArrVN = arrVisitNames.map(function (item) { return item.VisitName.toLowerCase() });
            var isDuplicateVN = valueArrVN.some(function (item, idx) {
                return valueArrVN.indexOf(item) != idx
            });
            if (isDuplicateVN) {
                alert(objVisitName.value + ' visit name already exists.')
                objVisitName.focus();
                return false;
            }

            var valueArrVS = arrVisitNames.map(function (item) { return item.VisitShort.toLowerCase() });
            var isDuplicateVS = valueArrVS.some(function (item, idx) {
                return valueArrVS.indexOf(item) != idx
            });
            if (isDuplicateVS) {
                alert(objVisitId.value + ' visit short name already exists.')
                objVisitId.focus();
                return false;
            }
        }
        return true;
    }

    const SubmitVisitSettingsData = (event) => {
        var retValgroup = ValidateGroups();
        if (retValgroup == true) {
            var retValvisit = ValidateStudySettings();
            if (retValgroup == true && retValvisit == true) {


                if (props.Action.toLowerCase() == 'add') {
                    return SaveVisitSettings();
                }
                else if (props.Action.toLowerCase() == 'edit') {
                    return updateVisitSettings();
                }
                else {
                    return true;
                }
            }
            else {
                return false;
            }
        }
    };

    const SaveVisitSettings = (event) => {
        if (props.Action.toLowerCase() == 'view')
            return true;

        let NoOfGroup = document.getElementById('txtNoOfGroup').value;
        let visitSettingModel = {
            StudyProtocolId: studyProtocolID,
            NoOfGroup: NoOfGroup,
            NoOfVisit: document.getElementById('txtNoOfVisit').value,
            Username: EDCUserID,
            listGroup: new Array(),
            listVisit: new Array()
        };

        var objGroup = document.getElementsByName("txtGroup");
        let objStudyGroupsModel = {};
        if (NoOfGroup == 1) {
            objStudyGroupsModel = { StudyGroupID: 0, StudyProtocolID: studyProtocolID, GroupID: 0, GroupName: 'Group 01', GroupShort: 'GRP01' };
            visitSettingModel.listGroup.push(objStudyGroupsModel);
        }
        else {
            for (var iCount = 0; iCount < objGroup.length; iCount++) {
                objStudyGroupsModel = { StudyGroupID: document.getElementById('hiddenGroup_' + iCount).value, StudyProtocolID: studyProtocolID, GroupID: iCount, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value };
                visitSettingModel.listGroup.push(objStudyGroupsModel);
            }
        }

        var objVisit = document.getElementsByName("txtVisit");
        let visit = {};
        let visitSeq = 0;
        for (var iCount = 0; iCount < objVisit.length; iCount++) {
            visitSeq = document.getElementById('hiddenVisitSeq_' + iCount).value;
            visit = { StudyVisitID: iCount, StudyProtocolID: studyProtocolID, VisitID: document.getElementById('hiddenVisit_' + iCount).value, VisitSequence: visitSeq, VisitName: document.getElementById('txtVisitName_' + iCount).value, VisitShort: document.getElementById('txtVisitId_' + iCount).value, VisitCategoryID: document.getElementById('ddlVisitCategory_' + iCount).value,  HiddenDefault: document.querySelector("input[name=HiddenByDefault_" + iCount + "]:checked").value, listGroupVisit: new Array() };
            if (NoOfGroup == 1) {
                visit.listGroupVisit.push({ StudyGVID: 0, StudyGroupID: 0, StudyVisitID: iCount, IncludeVisit: 'Y', GroupName: 'Group 01' });
            }
            else {
                let groupNames = UpdateGroups();
                for (var jCount = 0; jCount < groupNames.length; jCount++) {
                    if (document.getElementById('chkGroup_' + iCount + '_' + jCount).checked === true) {
                        visit.listGroupVisit.push({ StudyGVID: 0, StudyGroupID: groupNames[jCount].StudyGroupID, StudyVisitID: iCount, IncludeVisit: 'Y', GroupName: groupNames[jCount].GroupName });
                    }
                    else {
                        visit.listGroupVisit.push({ StudyGVID: 0, StudyGroupID: groupNames[jCount].StudyGroupID, StudyVisitID: iCount, IncludeVisit: 'N', GroupName: groupNames[jCount].GroupName });
                    }
                }
            }
            visitSettingModel.listVisit.push(visit);
        }

        fetch('api/VisitSetting/SaveVisitSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(visitSettingModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Visit settings not saved, Something went wrong.');
                return false;
            }
        })
        return true;
    };

    const updateVisitSettings = (event) => {
        if (props.Action.toLowerCase() == 'view')
            return true;
        
        let NoOfGroup = document.getElementById('txtNoOfGroup').value;
        let visitSettingModel = {
            StudyProtocolId: studyProtocolID,
            NoOfGroup: NoOfGroup,
            NoOfVisit: document.getElementById('txtNoOfVisit').value,
            Username: EDCUserID,
            listGroup: new Array(),
            listVisit: new Array()
        };

        var objGroup = document.getElementsByName("txtGroup");
        let objStudyGroupsModel = {};
        if (NoOfGroup == 1) {
            objStudyGroupsModel = { StudyGroupID: studyGroupId, StudyProtocolID: studyProtocolID, GroupID: 0, GroupName: 'Group 01', GroupShort: 'GRP01' };
            visitSettingModel.listGroup.push(objStudyGroupsModel);
        }
        else {
            for (var iCount = 0; iCount < objGroup.length; iCount++) {
                objStudyGroupsModel = { StudyGroupID: document.getElementById('hiddenGroup_' + iCount).value, StudyProtocolID: studyProtocolID, GroupID: iCount, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value };
                visitSettingModel.listGroup.push(objStudyGroupsModel);
            }
        }

        var objVisit = document.getElementsByName("txtVisit");
        let visit = {};
        let visitSeq = 0;
        for (var iCount = 0; iCount < objVisit.length; iCount++) {
            visitSeq = document.getElementById('hiddenVisitSeq_' + iCount).value;
            visit = { StudyVisitID: document.getElementById('hiddenVisit_' + iCount).value, StudyProtocolID: studyProtocolID, VisitID: document.getElementById('hiddenVisit_' + iCount).value, VisitSequence: visitSeq, VisitName: document.getElementById('txtVisitName_' + iCount).value, VisitShort: document.getElementById('txtVisitId_' + iCount).value, VisitCategoryID: document.getElementById('ddlVisitCategory_' + iCount).value, HiddenDefault: document.querySelector("input[name=HiddenByDefault_" + iCount + "]:checked").value, listGroupVisit: new Array() };

            if (NoOfGroup == 1) {
                visit.listGroupVisit.push({ StudyGVID: 0, StudyGroupID: 0, StudyVisitID: iCount, IncludeVisit: 'Y', GroupName: 'Group 01' });
            }
            else {

                let groupNames = UpdateGroups();
                for (var jCount = 0; jCount < groupNames.length; jCount++) {
                    if (document.getElementById('chkGroup_' + iCount + '_' + jCount).checked === true) {
                        visit.listGroupVisit.push({ StudyGVID: 0, StudyGroupID: groupNames[jCount].StudyGroupID, StudyVisitID: iCount, IncludeVisit: 'Y', GroupName: groupNames[jCount].GroupName });
                    }
                    else {
                        visit.listGroupVisit.push({ StudyGVID: 0, StudyGroupID: groupNames[jCount].StudyGroupID, StudyVisitID: iCount, IncludeVisit: 'N', GroupName: groupNames[jCount].GroupName });
                    }
                }
            }
            visitSettingModel.listVisit.push(visit);
        }

        fetch('api/VisitSetting/UpdateVisitSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(visitSettingModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Visit settings not updated, Something went wrong.');
                return false;
            }
        })
        return true;
    };

    const handleGroupInput = (inputEv, index) => {
        const value = inputEv.target.value;
        setItems((state) => state.map((val, i) => (i !== index ? val : value)));
    };

    const handleVisitInput = (inputEv, index) => {
       
        const value = inputEv.target.value;
        const newArray = vItems.map((item, i) => {

            if (index === i) {
                if (inputEv.target.id == 'txtVisitName_' + index) {
                    return { ...item, VisitName: value };
                }
                if (inputEv.target.id == 'txtVisitId_' + index) {
                    return { ...item, VisitShort: value };
                }
                if (inputEv.target.id == 'ddlVisitCategory_' + index) {
                    return { ...item, VisitCategoryID: value };
                }
            } else {
                return item;
            }
        });
        setVisitItems(newArray);
    };

    const handleHiddenDefault = (inputEv, index) => {
        const value = inputEv.target.value;
        const newArray = vItems.map((item, i) => {
            if (index === i) {
                return { ...item, HiddenDefault: value };
            } else {
                return item;
            }
        });
        setVisitItems(newArray);
    };

    const handleIncludeVisit = (inputEv, visitIndex, index) => {
        let value = '';
        if (document.getElementById(inputEv.target.id).checked == true) {
            value = 'Y';
        }
        else {
            value = 'N';
        }

        let arrVItem = [];
        for (var iCount = 0; iCount < vItems.length; iCount++) {
            for (var jCount = 0; jCount < vItems[iCount].listGroupVisit.length; jCount++) {
                if (visitIndex == iCount) {
                    if (index == jCount) {
                        vItems[iCount].listGroupVisit[jCount].IncludeVisit = value;
                    }
                }
            }
            arrVItem.push(vItems[iCount]);
        }
        setVisitItems(arrVItem);
    };

    const GroupNameHandler = ()  => {
        SetVisitItemsData();
    }

    function UpdateGroups() {
        let arrGroupNames = [];
        if (GroupCnt == 0) {
            alert('Number of cohorts/groups should not bve empty.');
            return false;
        }

        var objGroups = document.getElementsByName("txtGroup");
        for (var iCount = 0; iCount < objGroups.length; iCount++) {
            arrGroupNames.push({ StudyGVID: 0, StudyGroupID: 0, GroupName: document.getElementById('txtGroupName_' + iCount).value, GroupShort: document.getElementById('txtGroupShortName_' + iCount).value });
        }

        for (var iCount = 0; iCount < arrGroupNames.length; iCount++) {
            var grpName = arrGroupNames[iCount].GroupName;
            if (grpName == '') {
                alert('Group ' + (iCount + 1) + ' name should not be empty');
                document.getElementById('txtGroupName_' + iCount).focus();
                return false;
            }

            var groupShort = arrGroupNames[iCount].GroupShort;
            if (groupShort == '') {
                alert('Group short name should not be empty for group name' + (iCount + 1));
                document.getElementById('txtGroupShortName_' + iCount).focus();
                return false;
            }
        }
        return arrGroupNames;
    }

    const fetchVisitCategoryList = () => {
        fetch('api/VisitSetting/FillVisitCategoryList')
            .then(response => response.json())
            .then(data => {
                setVisitCategoryList(data);
            });
    }

    return (
        <Card>
            <Disable disabled={props.disabled}>
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <div className="row">
                                    <div className="col-sm-2">
                                        <label htmlFor="">Number of Cohorts/Groups:</label>
                                        <MandatoryLabel></MandatoryLabel>
                                </div>
                                <div className="col-sm-1">
                                    <InputNumber id="txtNoOfGroup" maxlength="2" value={GroupCnt} onchange={NoOfGroupChangeHandler}></InputNumber>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div id="divGroupList" style={{ display: "none" }}>
                            <div className={"row " + classes.rowstriped} style={{ borderTopStyle: "solid", borderWidth: "thin", borderColor: "silver", backgroundColor: "rgb(131, 192, 220)", color: "white", fontWeight: "bold", textAlign: "center" }}>
                                <div className="col-sm-1"></div>
                                <div className="col-sm-6"> Name of Group</div>
                        <div className="col-sm-5">Short Name</div>
                    </div>
                        {
                            items.map((item,index)=>{
                                    return <div key={index} className={"row " + classes.rowstriped} style={{ borderTopStyle: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                        <div className="col-sm-1">
                                            <label style={{ marginTop:"10px" }}>Group {index + 1}</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="hidden" id={"hiddenGroup_" + index} studygroupid={item.StudyGroupID} value={item.StudyGroupID} />
                                            <input id={"txtGroupName_" + index}
                                                type="text"
                                                value={item.GroupName}
                                                name="txtGroup"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(event) => handleGroupInput(event, index)}
                                                onKeyUp={GroupNameHandler}
                                            />
                                    </div>
                                    <div className="col-sm-5">
                                            <input id={"txtGroupShortName_" + index}
                                                type="text"
                                                value={item.GroupShort}
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(event) => handleGroupInput(event, index)}
                                            />
                                    </div>
                                </div>
                            })
                        }
                        </div>

                        <div className="row" style={{ marginTop : "30px" }}>
                        </div>
                    <div className="row mb-2">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <label htmlFor="">Number of Visits:</label>
                                        <MandatoryLabel></MandatoryLabel>
                                    </div>
                                    <div className="col-sm-1">
                                        <InputNumber id="txtNoOfVisit" value={VisitCnt} maxlength="2" onchange={NoOfVisitChangeHandler}></InputNumber>
                                    </div>
                                    <div className="col-sm-9">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="divVisitList" style={{ display: "none" }}>
                            <div className={"row " + classes.rowstriped} style={{ borderTopStyle: "solid", borderWidth: "thin", borderColor: "silver", backgroundColor: "rgb(131, 192, 220)", color: "white", fontWeight: "bold", textAlign: "center" }}>
                                <div className="col-sm-3 d-flex justify-content-center text-center"> Name of Visit</div>
                                <div className="col-sm-2 d-flex justify-content-center text-center">Short Name</div>
                                <div className="col-sm-2 d-flex justify-content-center text-center">Category</div>
                                <div className="col-sm-2 d-flex justify-content-center text-center"> Hidden By Default</div>
                                <div className="col-sm-3 d-flex justify-content-center text-center">Group</div>
                            </div>
                            {
                                vItems.map((item1, index) => {
                                    return <div key={index} className={"row " + classes.rowstriped} style={{ borderTopStyle: "solid", borderWidth: "thin", borderColor: "silver" }}>
                                        <input type="hidden" id={"hiddenVisitSeq_" + index} value={(index+1)} />
                                        <div className="col-sm-3">
                                            <input type="hidden" id={"hiddenVisit_" + index} studyvisitid={item1.StudyVisitID} value={item1.StudyVisitID} />
                                            <input id={"txtVisitName_" + index}
                                                type="text"
                                                value={item1.VisitName}
                                                name="txtVisit"
                                                className="form-control"
                                                maxlength="100"
                                                autoComplete="off"
                                                onChange={(event) => handleVisitInput(event, index)}
                                            />
                                </div>
                                        <div className="col-sm-2">
                                            <input id={"txtVisitId_" + index}
                                                type="text"
                                                value={item1.VisitShort}
                                                className="form-control"
                                                maxlength="50"
                                                autoComplete="off"
                                                onChange={(event) => handleVisitInput(event, index)}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <SelectList dataSource={visitCategoryList} id={"ddlVisitCategory_" + index} value={item1.VisitCategoryID} onChangeHandler={(event) => handleVisitInput(event, index)} ></SelectList>
                                        </div>
                                    <div className="col-sm-2 d-flex justify-content-center text-center">

                                            <input type="radio"
                                                className="form-check-input"
                                                id={"rdoHiddenByDefaultYes_" + index}
                                                name={"HiddenByDefault_" + index}
                                                value="Y"
                                                style={{ marginLeft: "10px", marginRight: "5px" }}
                                                checked={item1.HiddenDefault == 'Y' ? true : false}
                                                onChange={(event) => handleHiddenDefault(event, index)}
                                            />
                                            Yes

                                            <input type="radio"
                                                className="form-check-input"
                                                id={"rdoHiddenByDefaultNo_" + index}
                                                name={"HiddenByDefault_" + index}
                                                value="N"
                                                style={{ marginLeft: "10px", marginRight: "5px" }}
                                                checked={item1.HiddenDefault == 'N' ? true : false}
                                                onChange={(event) => handleHiddenDefault(event, index)}
                                            />
                                            No
                                   
                                </div>
                                        <div className="col-sm-3 d-flex justify-content-center text-center">
                                            {
                                                item1.listGroupVisit.map((item, index1) => {
                                                    return <div key={index1} style={{ display: "inline" }}>
                                                        <input type="hidden" id={"hiddenCheckedGroupId_" + item.StudyGVID} value={item.StudyGVID} groupname={item.GroupName} />
                                                        <input type="checkbox"
                                                            className="form-check-input"
                                                            id={"chkGroup_" + index + "_" + index1}
                                                            style={{ marginLeft: "10px", marginRight: "5px" }}
                                                            checked={item.IncludeVisit == 'Y' ? true : false}
                                                            name="chkGroup"
                                                            onChange={(event) => handleIncludeVisit(event, index, index1)}
                                                           />
                                                        <label htmlFor={"chkGroup_" + index + "_" + index1}> {item.GroupName} </label>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    
                    </div>
                    <div className="col-sm-1"></div>
                </div>
            </Disable>
            <div className="mt-2" style={{ textAlign: "center" }}>
                <props.ActionButton action={props.Action} component="VisitSettings" SaveVisitSettings={SubmitVisitSettingsData} {...props} />
            </div>
            </Card>
    );
};

export default VisitSettings;
